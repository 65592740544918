.chat-container { /* Scope the styles to the container class */
    html,
    body,
    #root {
      margin: unset;
      padding: unset;
      height: 100%;
    }
    #root {
      display: flex;
      height: 100%;
      .str-chat__channel-list {
        position: fixed;
        z-index: 1;
        height: 100%;
        width: 0;
        flex-shrink: 0;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
        &--open {
          width: 30%;
          position: fixed;
        }
        transition: width 0.3s ease-out;
      }
      .str-chat__channel {
        flex: 1;
        min-width: 0;
      }
      .str-chat__main-panel {
        min-width: 0;
        flex: 1;
        &--thread-open {
          display: none;
        }
      }
    }
  }